import React, { createRef, useEffect, useRef, useState } from "react";
import bg from "../../assets/images/landing-bg.png";
import "./LandingSection.css";
import logo from "../../assets/images/CCfinallogo_boxed.png";
import landingImg from "../../assets/images/Slider1.png";
import shape from "../../assets/images/shape.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import heroVideo from "../../assets/images/hero_section_clip.mp4";
import heroBg from "../../assets/images/hero_bg_1.png";
import CClogo from "../../assets/images/CCfinallogo_boxed.png";
import repeatImg from "../../assets/images/repeatImg.png";
import posterImg from "../../assets/images/login-car-1.png";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import loadImgGif from "../../assets/images/load-img.gif";
import { GoUnmute, GoMute } from "react-icons/go";
import { FaCirclePlay } from "react-icons/fa6";

const LandingSection = ({ content }) => {
  const videoFormats = ["mp4", "avi", "mkv", "mov", "webm"];
  const imageFormats = ["jpg", "jpeg", "png", "gif", "webp"];
  const parseTitle = (title) => {
    const parts = [];

    // Regular expression to match <span> tags
    const spanRegex = /<span.*?>(.*?)<\/span>/g;

    let match;
    let lastIndex = 0;

    // Iterate through matches in the title
    while ((match = spanRegex.exec(title)) !== null) {
      // Add text before the match as a part
      if (match.index > lastIndex) {
        parts.push({
          type: "text",
          content: title.substring(lastIndex, match.index),
        });
      }

      // Add the span content as a part
      parts.push({ type: "span", content: match[1] });

      // Update the last index
      lastIndex = spanRegex.lastIndex;
    }

    // Add the remaining text as a part
    if (lastIndex < title.length) {
      parts.push({ type: "text", content: title.substring(lastIndex) });
    }

    return parts;
  };

  const location = useLocation();
  const [stopAudio, setStopAudio] = useState(false);
  useEffect(() => {
    if (location?.state?.stopAudio) {
      setStopAudio(true);
    }
  }, [location.state]);
  useEffect(() => {
    // const handleScroll = () => {
    //   let isInViewport = false;
    //   const currentVideoRef = videoRefs.current[activeVideoIndex];
    //   const sectionRect = currentVideoRef.current.getBoundingClientRect();
    //   if (sectionRect) {
    //     isInViewport =
    //       sectionRect.top >= 0 && sectionRect.bottom <= window.innerHeight;
    //     if (!isInViewport) {
    //       // setVideoMuted((prevMuted) => !prevMuted);
    //       currentVideoRef.current.pause();
    //     } else {
    //       if (!currentVideoRef.current.paused) {
    //         currentVideoRef.current.play();
    //       }
    //     }
    //   }
    // };
    // window.addEventListener("scroll", handleScroll);
    // return () => {
    //   window.removeEventListener("scroll", handleScroll);
    // };
  });

  // VIDEO SOUND PLAY
  const [showPlayBtn, setShowPlayBtn] = useState(true);
  const [activeVideoIndex, setActiveVideoIndex] = useState(0);
  const [pauseCurrentVideo, setPauseCurrentVideo] = useState(false);
  const videoRefs = useRef(
    content && content.length > 0 && content.map(() => createRef())
  );
  console.log(videoRefs);
  const [isAutoPlay, setIsAutoPlay] = useState(true);
  const [videoMuted, setVideoMuted] = useState(true);

  const playAudio = (index, prevVideoMuted) => {
    if (stopAudio) {
      setStopAudio(false);
    }
    setVideoMuted((prevMuted) => !prevMuted);

    const currentVideoRef = videoRefs.current[index].current;
    if (currentVideoRef) {
      currentVideoRef.play();
      currentVideoRef.muted = !prevVideoMuted ? "false" : "true";
    }
    // Mute all videos if the previous video was not muted
    // if (!prevVideoMuted) {
    //   videoRefs.current.forEach((videoRef) => {
    //     if (videoRef.current) {
    //       videoRef.current.muted = "true";
    //     }
    //   });
    // }
  };

  useEffect(() => {
    if (videoMuted) {
      setIsAutoPlay(true);
    } else {
      setIsAutoPlay(false);
    }
  }, [videoMuted]);

  const onMoved = (splide, newIndex, prevIndex) => {
    setShowPlayBtn(false);
    setPauseCurrentVideo(false);
    const prevVideoRef = videoRefs.current[prevIndex].current;
    if (prevVideoRef) {
      prevVideoRef.pause();
      prevVideoRef.currentTime = 0; // Reset the video to the beginning
      prevVideoRef.muted = "true";

      setIsAutoPlay(true);
    }

    const currentVideoRef = videoRefs.current[newIndex].current;
    if (currentVideoRef) {
      currentVideoRef.play();
    }
    // Set the active video index for the current slide (NOT NEEDED NOW)
    setActiveVideoIndex(newIndex);
  };

  useEffect(() => {
    // setShowPlayBtn(false);
    return () => {
      videoRefs.current.forEach((videoRef) => {
        if (videoRef.current) {
          videoRef.current = null;
        }
      });
      videoRefs.current = null;
    };
  }, []);
  return (
    <section
      style={{
        background: `url(${heroBg})`,
        backgroundSize: "cover",
        minHeight: "75vh",
      }}
    >
      <Splide
        onMoved={onMoved}
        options={{
          type: "loop",
          perPage: 1,
          rewind: true,
          width: "100%",
          pauseOnHover: true,
          easing: "cubic-bezier(0.25, 1, 0.5, 1)",
          speed: 2000,
          rewindSpeed: 1500,
          arrows: false,
          pagination: true,
          // drag: "free",
          classes: {
            pagination: "splide__pagination pagination_container",
            page: "splide__pagination__page page_container",
          },
          autoplay: isAutoPlay,
          interval: 8000,
        }}
      >
        {content.length > 0 &&
          content.map((item, index) => {
            const parts = item.title.split(/(<span.*?>|<\/span>)/);
            return (
              <SplideSlide key={index}>
                <div className="landing-section" style={{ padding: "1rem" }}>
                  <div className="landing-sec-right" role="contentinfo" aria-label="content">
                    <h1 className="large-text bold-text background-text gradient-text">
                      {parseTitle(item.title).map((part, partIndex) => {
                        if (part.type === "text") {
                          // Render plain text
                          return <span key={partIndex}>{part.content}</span>;
                        } else if (part.type === "span") {
                          // Render the content inside the span
                          return (
                            <span key={partIndex} className="element">
                              {part.content}
                            </span>
                          );
                        }

                        return null;
                      })}
                    </h1>
                    <div className="h3 secondary-text my-1">
                      {/* HANG → SHOP → WEAR */}
                      {item.subtitle}
                    </div>
                    {index == 0 && (
                      <img
                        alt="repeatImg"
                        src={repeatImg}
                        style={{ width: "200px" }}
                      />
                    )}
                    <br />
                    <div className="landing-sec-btn-con">
                      {item.buttons &&
                        item.buttons.length > 0 &&
                        item.buttons.map((btn, ind) => (
                          <Link to={"/" + btn.url} className="btn">
                            {" "}
                            {btn.btn}
                          </Link>
                        ))}
                    </div>
                  </div>
                  <div className="landing-sec-left">
                    <div className="img-con" style={{ position: "relative" }}>
                      {pauseCurrentVideo && (
                        <div
                          className="video-play-btn"
                          onClick={() => {
                            // playAudio(index, videoMuted);
                            // setShowPlayBtn(false);
                            if (videoRefs.current[index].current.paused) {
                              videoRefs.current[index].current.play();
                              setPauseCurrentVideo(false);
                            } else {
                              setPauseCurrentVideo(true);
                              videoRefs.current[index].current.pause();
                            }
                          }}
                          style={{
                            display: pauseCurrentVideo ? "flex" : "none",
                          }}
                        >
                          <FaCirclePlay
                            size={"3em"}
                            color="var(--primary-color)"
                          />
                          <span class="dot_animation"></span>
                        </div>
                      )}
                      {videoFormats.includes(
                        item.videoUrl.split(".").pop().toLowerCase()
                      ) ? (
                        <div style={{ height: "98%" }}>
                          <div
                            onClick={(e) => {
                              if (videoRefs.current[index].current.paused) {
                                videoRefs.current[index].current.play();
                                setPauseCurrentVideo(false);
                              } else {
                                setPauseCurrentVideo(true);
                                videoRefs.current[index].current.pause();
                              }
                            }}
                          >
                            <video
                              ref={videoRefs.current[index]}
                              loop={true}
                              width="100%"
                              height="100%"
                              preload="auto"
                              autoPlay={index === 0 && stopAudio && true}
                              // controls={true}
                              // autoPlay={false}
                              // muted={true}
                              // muted={JSON.stringify(videoMuted)}
                              muted={videoMuted}
                              style={{ borderRadius: "5px" }}
                              // poster={posterImg}
                            >
                              <source
                                src={
                                  process.env
                                    .REACT_APP_VIDEO_UPLOAD_SERVER_URL +
                                  "uploads/" +
                                  item.videoUrl
                                }
                                type="video/mp4"
                              />
                            </video>
                          </div>
                          {!pauseCurrentVideo && (
                            <div
                              id="muteUnmuteBtn"
                              style={{
                                position: "absolute",
                                bottom: "10px",
                                right: "10px",
                                cursor: "pointer",
                                background: "#fff",
                                borderRadius: "50%",
                                boxShadow:
                                  "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                padding: "8px",
                                display: "flex",
                                zIndex: 1,
                              }}
                              role="button"
                              aria-label="Mute Unmute Button"
                              tabIndex={0}
                              onClick={() => playAudio(index, videoMuted)}
                            >
                              {/* Add your play icon or any other icon here */}
                              {
                                index === 0 && stopAudio ? (
                                  <GoMute color="var(--primary-color)" />
                                ) : videoRefs.current[index]?.current?.muted ? (
                                  <GoMute color="var(--primary-color)" />
                                ) : (
                                  // "🔇"
                                  <GoUnmute color="var(--primary-color)" />
                                )
                                // "🔊"
                              }
                            </div>
                          )}
                        </div>
                      ) : (
                        <img
                          alt={`video ${index}`}
                          src={item.videoUrl}
                          width={"100%"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </SplideSlide>
            );
          })}
      </Splide>
    </section>
  );
};

export default LandingSection;
