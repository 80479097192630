import React, { useEffect, useState } from "react";
import "./NewFooter.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BsFacebook,
  BsInstagram,
  BsPinterest,
  BsTiktok,
  BsTwitterX,
} from "react-icons/bs";
import NewsLetter from "../NewsLetter/NewsLetter";
import { useDispatch, useSelector } from "react-redux";
import { getStaticContent } from "../StateSlices/staticContentSlice";
import Modal from "react-modal"
import { FaFacebookF, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { referLink, resetItem } from "../StateSlices/referLinkSlice";
import { MdContentCopy } from "react-icons/md";

const NewFooter = ({ content }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { staticContentInfo } = useSelector((state) => state.staticContent);
  const [spinner, setSpinner] = useState(false);

  const [consent, setConsent] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setConsent(true);
    }, 500);
  }, []);

  // const [loading, setLoading] = useState(false);
  // const [data, setData] = useState(null);
  // console.log("From Footer Content ", content);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   setLoading(true);
  //   dispatch(
  //     getStaticContent({
  //       values: { section: "footer" },
  //     })
  //   );
  // }, []);

  // useEffect(() => {
  //   if (staticContentInfo) {
  //     setData(staticContentInfo);
  //     console.log("k2l ", data);
  //     setLoading(false);
  //   }
  // }, [staticContentInfo]);
  const toastOption = {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const { referStatus, referInfo, referError } = useSelector(
    (state) => state.referLink
  );

  const [isReferModalActive, setIsReferModalActive] = useState(false);
  const [refUrl, setRefUrl] = useState("");
  // console.log("header2", userInfo, profile);

  if (referInfo) {
    let referId = referInfo;
    dispatch(resetItem());
    setRefUrl(
      "https://closestcloset.com/signup?referral=" + encodeURIComponent(referId)
    );
    setIsReferModalActive((prev) => !prev);
  }

  const getUrl = () => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(referLink({ token: token }));
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <footer>
        <div class="row primary" style={{ margin: "0 auto" }}>
          {content &&
            content.length > 0 &&
            content.map((col, ind) => (
              <>
                {ind === 0 && (
                  <div class="column links quick-links" key={ind}>
                   
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "flex-start",
                        columnGap: "4rem",
                        rowGap: "1rem",
                        flexWrap: "wrap",
                      }}
                    >
                      <div>
                      <h3>{col.name}</h3>
                      <ul>
                        {col.links &&
                          col.links.slice(0, 6).map((link, ind) => (
                            <li key={ind}>
                              {link.display === "Refer a Friend, Earn Hanger Credits" ? <Link onClick={() => getUrl()}>{link.display}</Link> : <Link to={"/" + link.link}>{link.display}</Link>} 
                            </li>
                          ))}
                      </ul>
                      </div>
                      <div>
                        <h3>Learn More  </h3>
                      <ul>
                        {col.links &&
                          col.links.slice(6).map((link, ind) => (
                            <li key={ind}>
                              <Link to={"/" + link.link}>{link.display}</Link>
                            </li>
                          ))}
                      </ul>
                      </div>
                    </div>
                  </div>
                )}

                {ind === 1 && (
                  <div class="column links" style={{ marginTop: 0 }}>
                    <h3>{col.name}</h3>
                    <ul>
                      {col.links.map((link, ind) => (
                        <li>
                          <i
                            class={link.icon}
                            style={{ marginRight: "1rem" }}
                          ></i>
                          <span>{link.display}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {ind === 2 && (
                  <div class="column subscribe">
                    <h3>{col.name}</h3>
                    <NewsLetter
                      toast={toast}
                      setSpinner={setSpinner}
                      toastOption={toastOption}
                    />
                    <div class="social">
                      {col.links.map((link, ind) => (
                        <a
                          href={link.link}
                          target="_blank"
                          aria-label={
                            ind === 0
                              ? "Visit Instagram"
                              : ind === 1
                              ? "Visit Facebook"
                              : ind === 2
                              ? "Visit TikTok"
                              : ind === 3
                              ? "Visit Twitter"
                              : ind === 4
                              ? "Visit Pinterest"
                              : "Social Media Link"
                          }
                          key={ind}
                        >
                          {ind === 0 && <BsInstagram size={".75em"} />}
                          {ind === 1 && <BsFacebook size={".75em"} />}
                          {ind === 2 && <BsTiktok size={".75em"} />}
                          {ind === 3 && <BsTwitterX size={".75em"} />}
                          {ind === 4 && <BsPinterest size={".75em"} />}
                        </a>
                      ))}
                    </div>
                  </div>
                )}
              </>
            ))}
        </div>
        <div class="row copyright">
          {/* <p>Copyright  2021 Coding Artist | All Rights Reserved</p> */}
          <p>
            &copy; 2023 Closest LLC |{" "}
            <Link
              to="/privacy-policy"
              style={{ color: "", textDecoration: "none" }}
              className="hover-link"
            >
              Privacy Policy
            </Link>{" "}
            |{" "}
            <Link
              className="hover-link"
              to="/terms-conditions"
              style={{ color: "", textDecoration: "none" }}
            >
              Terms & Conditions
            </Link>
            |{" "}
            <Link
              className="hover-link"
              to="/cookie-policy"
              style={{ color: "", textDecoration: "none" }}
            >
              Cookie Policy
            </Link>
            {consent ? (
              <>
                |{" "}
                <Link
                  className="termly-display-preferences"
                  onClick={(e) => {
                    e.preventDefault();
                    // Your custom behavior here
                    console.log("Consent Preferences clicked");
                  }}
                >
                  Consent Preferences
                </Link>
              </>
            ) : (
              ""
            )}
            |{" "}
            <Link
              className="hover-link"
              to="/disclaimer"
              style={{ color: "", textDecoration: "none" }}
            >
              Disclaimer
            </Link>
            |{" "}
            <Link
              className="hover-link"
              to="/acceptable-use-policy"
              style={{ color: "", textDecoration: "none" }}
            >
              Acceptable Use Policy
            </Link>
            {/* <a href="#" class="termly-display-preferences">Consent Preferences</a> */}
            | All Rights Reserved.
          </p>
        </div>
      </footer>

      <Modal
        aria-modal="true"
        role="dialog"
        ariaHideApp={false}
        isOpen={isReferModalActive}
        onRequestClose={() => setIsReferModalActive(false)}
        style={{
          overlay: {
            background: "rgba(0,0,0,0.5)",
            zIndex: 70,
          },
          content: {
            maxWidth: "600px",
            maxHeight: "fit-content",
            margin: "auto",
            position: "absolute",
          },
        }}
        contentLabel="Example Modal"
      >
        <div
          className="close_container"
          style={{ display: "flex", justifyContent: "end" }}
        >
          {/* <button
              className="btn dark-btn"
              style={{ padding: "4px 9.5px", borderRadius: "2px" }}
              onClick={() => this.setState({ modalIsOpen: false })}
            >
              X
            </button> */}
        </div>

        <div className="terms-modal-con">
          <div
            className="terms-modal-wrapper"
            style={{ marginTop: 0, padding: "1.5rem 0", paddingTop: ".5rem" }}
          >
            <h3 className="primary-text" style={{ marginBottom: ".5rem" }}>
              Refer to your friends
            </h3>
            <h6 className="background-text">
              Members earn 5 bonus hanger credits + $5 off their next
              subscription fee on each paid member referral!
            </h6>
            <div className="reference-section">
              <input type="text" id="reference-input" value={refUrl} disabled />
              <button
                id="copy-button"
                onClick={() => {
                  // refUrl.select();
                  // refUrl.setSelectionRange(0, 99999); // For mobile devices
                  // Copy the text inside the text field
                  navigator.clipboard.writeText(refUrl);
                  toast.success(
                    "Referral URL copied successfully!",
                    toastOption
                  );
                }}
              >
                <MdContentCopy />
              </button>
            </div>
            <div className="share-section">
              <button style={{background: "#3b5998", color: "white"}} onClick={() => {
                // ALSO WRITE SOME NICE MESSAGE
                // give it like use my referral link to get 5 bonus hanger credits + $5 off your next subscription fee. Here's the link:
                const message = "I want to shop your closet!  Join me @  closestcloset.com where I am able to post my own fashion items that I'll never wear again to earn hanger credits that I can use to shop other members' items that I actually will wear for FREE...check it out here and if you sign up for a monthly or annual membership with this link, you'll get a bonus 5 free hanger credits (6 total!)";
                console.log(refUrl)
                window.open(
                  `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    refUrl
                  )}`
                );
              }}>
                <FaFacebookF size={25}/>
                
              </button>

              {/*  make Twittter X */}
              <button style={{background: "#000", color: "white"}} onClick={() => {
                const message = "📢👋I want to shop your closet!  Join me @  closestcloset.com where I shop for FREE...check it out here and if you sign up for a monthly or annual membership with this link, you'll get a bonus 5 free hanger credits (6 total!)";
                window.open(
                  `https://twitter.com/intent/tweet?url=${encodeURIComponent(
                    refUrl
                  )}&text=${encodeURIComponent(message)}`
                );
              }}>
                <BsTwitterX size={25}/>
              </button>
              {/*  add this also below background: linear-gradient(
    to right,
    #833ab4,#fd1d1d,#fcb045
  ); */}
              <button style={{ background: "#833ab4", background: "linear-gradient(145deg, #833ab4,#fd1d1d,#fcb045)",color: "white"}} onClick={() => {
                window.open(
                  `https://www.instagram.com/`
                );
              }}>
                <FaInstagram size={25}/>
              </button>
              <button style={{background: "#25D366", color: "white"}} onClick={() => {
                const message = "📢👋I want to shop your closet!  Join me @  closestcloset.com where I am able to post my own fashion items that I'll never wear again to earn hanger credits that I can use to shop other members' items that I actually will wear for FREE...check it out here and if you sign up for a monthly or annual membership with this link, you'll get a bonus 5 free hanger credits (6 total!)";
                window.open(
                  `https://api.whatsapp.com/send?text=${message}${encodeURIComponent(
                    refUrl
                  )}`
                );
              }}>
                <FaWhatsapp size={25}/>
              </button>
              {/* TikTok */}
              {/* <button style={{background: "#000", color: "white"}} onClick={() => {
                window.open(
                  `https://www.tiktok.com/`
                );
              }}>
                <FaTiktok size={25}/>
              </button> */}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NewFooter;
