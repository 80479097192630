import React, { useEffect, useRef, useState } from "react";
import "./Header1.css";
import logo from "../../assets/images/CCfinallogo_boxed.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reset } from "../StateSlices/getuserSlice";
import Search from "../Search/Search";
import { getSearchResult } from "../StateSlices/searchSlice";
import bag from "../../assets/images/bag.svg";
import { BiCog } from "react-icons/bi";
import { HiOutlineChat, HiOutlineShoppingBag } from "react-icons/hi";
import { BsBag, BsBagCheck } from "react-icons/bs";

const Header1 = ({ cart }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { status, userInfo, error, profile } = useSelector(
    (state) => state.user
  );

  const [searchActive, setSearchActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const logout = () => {
    localStorage.removeItem("clttoken");
    dispatch(reset());
    navigate("/", { state: { stopAudio: "value" } });
  };

  const [spinner, setSpinner] = useState(false);
  const { searchinfo, searchstatus } = useSelector((state) => state.search);

  const header1 = useRef();

  const handleSearch = (e) => {
    e.preventDefault();
    // console.log(searchQuery);
    navigate(`/search/${searchQuery}`);
  };

  // useEffect(() => {
  //   if (searchinfo) {
  //     setSpinner(false);
  //   }
  // }, [searchinfo]);
  // useEffect(() => {
  //   dispatch(getSearchResult(searchQuery));
  //   setSpinner(true);
  // }, []);
  const [profileMenu, setProfileMenu] = useState(false);
  return (
    <>
      {/* <div
        id="g_id_onload"
        data-client_id="847743167985-8r5scn91q6pbpo2m85jilhmvj3bjog14.apps.googleusercontent.com"
        data-context="signin"
        data-ux_mode="popup"
        data-login_uri="https://closest-closet.com/my-closet"
        data-itp_support="true"
      ></div> */}

      {/* <div
        class="g_id_signin"
        data-type="standard"
        data-shape="rectangular"
        data-theme="outline"
        data-text="signin_with"
        data-size="large"
        data-logo_alignment="left"
      ></div> */}
      <div
        className="header1"
        onClick={(e) => {
          e.target.parentNode.className == "header1" &&
            searchActive == true &&
            setSearchActive(false);
        }}
      >
        <div className="header site-width">
          <div className="header_left">
            <Link
              to={`/`}
              state={{ stopAudio: true }}
              style={{ textDecoration: "none", textAlign: "center" 
                // display: "flex", alignItems: "center", flexDirection: "column"
               }}
            >
              <img
                src={logo}
                style={{ height: "60px" }}
                alt="Closest Closet Logo"
              />
              <div style={{textAlign: "center", marginTop: "3px", color: "var(--primary-color)", fontSize: "10px",   }}>Style that circles back</div>
            </Link>
            <form className="searchbar_container" onSubmit={handleSearch} role="search">
              <input
                type="text"
                className="input_search"
                value={searchQuery}
                // onClick={() => setSearchActive(true)}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ borderRadius: "5px 0 0 5px", padding: "12px" }}
                // onBlur={() => setSearchActive(false)}
                // onMouseUp={() => sear}
                aria-label="Search Product"
                role="search"
              />
              <button
                type="submit"
                className="btn"
                style={{ padding: "11.5px 25px", borderRadius: "0 5px 5px 0" }}
                aria-label="Perform Search"
              >
                <i class="fas fa-search fa-lg"></i>
              </button>
            </form>
          </div>

          <div className="header_right">
            {userInfo && userInfo.role === "admin" ? (
              <Link
                to={`/admin/users`}
                style={{ textDecoration: "none", display: "flex" }}
                className="icon_container icon_con_2"
                title="Admin Panel"
              >
                <button type="button" class="header-icon-button">
                  <BiCog
                    size={32}
                    color="var(--primary-color)"
                    style={{ cursor: "pointer" }}
                  />
                </button>
              </Link>
            ) : (
              ""
            )}
            <Link
              to={`/chat-support`}
              style={{ textDecoration: "none", display: "flex" }}
              className="icon_container icon_con_2"
              title="Chat"
            >
              <button type="button" class="header-icon-button">
                <HiOutlineChat
                  color="var(--primary-color)"
                  style={{ cursor: "pointer" }}
                  size={32}
                />
                {/* <span class="icon-button__badge">
                {userInfo &&
                (userInfo.boughtUnreadCount > 0 ||
                  userInfo.unreadSoldCount > 0 ||
                  userInfo.hangerUnreadCount ||
                  (userInfo.userChatHistoryId &&
                    userInfo.userChatHistoryId.chatUnreadCount > 0))
                  ? "ringing-bell"
                  : ""}
              </span> */}
              </button>
            </Link>
            {userInfo && userInfo.userDetailsId ? (
              <div className="shop-dropdown">
                <div className="icon_container">
                  <span style={{ cursor: "pointer", marginRight: 0 }}>
                    <i
                      className={`far fa-bell ${
                        userInfo &&
                        (userInfo.boughtUnreadCount > 0 ||
                          userInfo.unreadSoldCount > 0 ||
                          userInfo.hangerUnreadCount ||
                          (userInfo.userChatHistoryId &&
                            userInfo.userChatHistoryId.chatUnreadCount > 0))
                          ? "ringing-bell"
                          : ""
                      }`}
                    ></i>
                  </span>
                </div>
                <div className="shop-dropdown-content">
                  <Link to={"/chat-support"}>
                    <div className="dropdown-menu notif-item">
                      <div>Chat</div>
                      <div className="notif-dot small-text">
                        {userInfo &&
                          userInfo.userChatHistoryId &&
                          userInfo.userChatHistoryId.chatUnreadCount}
                      </div>
                    </div>
                  </Link>
                  <Link to={"/user/purchases"}>
                    <div className="dropdown-menu notif-item">
                      <div>Bought</div>
                      <div className="notif-dot small-text">
                        {userInfo && userInfo.boughtUnreadCount}
                      </div>
                    </div>
                  </Link>
                  <Link to={"/user/orders"}>
                    <div className="dropdown-menu notif-item">
                      <div>Sold</div>
                      <div className="notif-dot small-text">
                        {userInfo && userInfo.unreadSoldCount}
                      </div>
                    </div>
                  </Link>
                  <Link to={"/user/hanger"}>
                    <div className="dropdown-menu notif-item">
                      <div>Hangers</div>
                      <div className="notif-dot small-text">
                        {userInfo && userInfo.hangerUnreadCount}
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ) : (
              ""
            )}

            {userInfo && userInfo.userDetailsId ? (
              <>
                <Link
                  to={`/checkout`}
                  style={{ textDecoration: "none", display: "flex" }}
                  className="icon_container icon_con_2"
                  title="Checkout"
                >
                  <button type="button" class="header-icon-button">
                    <HiOutlineShoppingBag
                      color="var(--primary-color)"
                      style={{ cursor: "pointer" }}
                      size={32}
                    />
                    {userInfo.userProductsId.cartId.length > 0 && (
                      <span class="icon-button__badge">
                        {userInfo.userProductsId.cartId.length}
                      </span>
                    )}
                  </button>
                </Link>
              </>
            ) : (
              <Link
                to={`/checkout`}
                style={{ textDecoration: "none", display: "flex" }}
                className="icon_container icon_con_2"
                title="Checkout"
              >
                <button type="button" class="header-icon-button">
                  <HiOutlineShoppingBag
                    color="var(--primary-color)"
                    style={{ cursor: "pointer" }}
                    size={32}
                  />
                  {cart.length > 0 && (
                    <span class="icon-button__badge">{cart.length}</span>
                  )}
                </button>
              </Link>
            )}

            <div className="profile_menu icon_container">
              {userInfo && userInfo.userDetailsId ? (
                <div className="dropdown_icon">
                  <img
                    onMouseOver={() => {
                      setProfileMenu((prevState) => !prevState);
                    }}
                    // onClick={() => setProfileMenu((prev) => !prev)}
                    className="user_logo_header"
                    style={{ padding: 0, marginTop: "2px" }}
                    src={
                      userInfo.userDetailsId.userIcon
                        ? userInfo.userDetailsId.userIcon
                        : logo
                    }
                    alt="user-icon"
                  />
                  <div className="dropdown">
                    <Link
                      to={`/user/my-closet`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div className="dropdown_menu">My Closet</div>
                    </Link>
                    <div className="dropdown_menu" onClick={logout}>
                      Logout
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center", }}>
                  <Link
                    to="./signup"
                    className="icon_container icon_con_2"
                    style={{ padding: 0, color: "var(--white-color)" }}
                  >
                    <div
                      style={{
                        padding: ".5rem 1rem",
                        background: "var(--primary-color)",
                        color: "var(--white-color)",
                        borderRadius: "30px",
                        // minWidth: "75px",
                        textAlign: "center",
                        height: 35
                      }}
                    >
                      Signup
                    </div>
                  </Link>
                  &nbsp;
                  <Link
                    to="./login"
                    className="icon_container icon_con_2"
                    style={{ padding: 0, color: "var(--primary-color)", display: "flex", justifyContent: "center",  alignItems: "center" }}
                  >
                    <div
                      style={{
                        padding: ".5rem 1rem",
                        background: "transparent",
                        color: "var(--primary-color)",
                        border: "1px solid var(--primary-color)",
                        borderRadius: "30px",
                        // minWidth: "75px",
                        textAlign: "center",
                        height: 35
                      }}
                    >
                      Login
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {searchActive ? (
        <Search
          searchActive={searchActive}
          setSearchActive={() => setSearchActive()}
          searchQuery={searchQuery}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Header1;
