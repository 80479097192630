import React, { useEffect, useRef, useState } from "react";
import "./SignupForm.css";
import { Link } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import InjectedCheckoutForm from "../CardSection/CheckoutForm";
import { useFormikContext } from "formik";
import axios from "axios";
import {
  LoginSocialGoogle,
  LoginSocialAmazon,
  LoginSocialFacebook,
  IResolveParams,
} from "reactjs-social-login";

import {
  FacebookLoginButton,
  GoogleLoginButton,
  AmazonLoginButton,
} from "react-social-login-buttons";

import stripebadge2 from "../../assets/images/stripe-badge-grey-2.png";
import Modal from "react-modal";
import Select from "react-select";
import { useGoogleOneTapLogin } from 'react-google-one-tap-login';

// const REDIRECT_URI =
//   'https://plenty-planets-beam-42-118-51-2.loca.lt/account/login';
const REDIRECT_URI = "https://localhost:3000/login";
// const stripePromise = loadStripe("pk_live_AEfhNK2o4rBBoKqhTFWMQe2N");

const stripePromise = loadStripe("pk_live_AEfhNK2o4rBBoKqhTFWMQe2N");

const AutoComplete = ({
  setFieldValue,
  statesList,
  setStateSelection,
  handleChange,
  values,
  handleBlur,
}) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();

  const initializeAutocomplete = () => {
    if (!window.google || !window.google.maps || !window.google.maps.places) {
      console.error("Google Maps JavaScript API not loaded.");
      return;
    }

    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      {
        componentRestrictions: { country: "us" },
        fields: ["address_components", "geometry", "icon", "name"],
        types: ["address"],
      }
    );

    autoCompleteRef.current.addListener("place_changed", () => {
      const place = autoCompleteRef.current.getPlace();

      if (!place || !place.address_components) {
        console.error("Place details not found.");
        return;
      }
      debugger;
      const addressComponents = place.address_components;
      let street = "",
        city = "",
        statee = "",
        zip = "";

      addressComponents.forEach((component) => {
        const componentType = component.types[0];

        switch (componentType) {
          case "street_number":
            street = `${component.long_name} ${street}`;
            break;
          case "route":
            street += component.long_name;
            break;
          case "locality":
            city = component.long_name;
            break;
          case "administrative_area_level_1":
            statee = component.long_name;
            break;
          case "postal_code":
            zip = component.long_name;
            break;
          default:
            break;
        }
      });
      let stateOption = statesList.filter(
        (s) => s.State.toLowerCase() === statee.toLowerCase()
      );
      if (stateOption && stateOption.length > 0) {
        let selectedOption = {
          label: stateOption[0].State + ", " + stateOption[0].Abbreviation,
          value: stateOption[0].State,
        };
        setStateSelection(selectedOption);
        setFieldValue("state", selectedOption.value);
      }
      setFieldValue("address", street);
      setFieldValue("city", city);
      setFieldValue("zipCode", zip);
    });
  };

  useEffect(() => {
    if (window.google && window.google.maps && window.google.maps.places) {
      initializeAutocomplete();
    } else {
      const intervalId = setInterval(() => {
        if (window.google && window.google.maps && window.google.maps.places) {
          clearInterval(intervalId);
          initializeAutocomplete();
        }
      }, 100);
      return () => clearInterval(intervalId);
    }
  }, []);

  

  return (
    <div>
      <label htmlFor="address">Street Address*</label>

      <input
        placeholder=""
        type="text"
        id="address"
        name="address"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.address}
        ref={inputRef}
        required={true}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      />
    </div>
  );
};

const SignupForm = ({
  setTc,
  tc,
  status,
  formRef,
  handleSubmit,
  handleChange,
  handleBlur,
  values,
  errors,
  setId,
  membership,
  setFieldValue,
  setProvider,
  provider,
  checkCoupen,
  discount,
  discountPercent,
  setCoupen,
  coupen,
  disableCoupen,
  setDisableCoupen,
  setDiscountPercent,
  setDiscount,
  touched,
  setCoupenApplied,
  spinner,
  setSpinner,
  ScrollToFieldError,
  stateSelection,
  setStateSelection,
  sList,
  statesList,
}) => {
  const { submitForm } = useFormikContext();
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const openPopup = () => {
    const width = window.innerWidth * 0.75;
    const height = window.innerHeight * 0.75;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    const popupWindow = window.open(
      "https://closestcloset.com/terms-conditions",
      "_blank",
      `width=${width}, height=${height}, left=${left}, top=${top}`
    );

    // Add event listener to close the popup window when the component unmounts
    window.addEventListener("beforeunload", () => {
      if (popupWindow && !popupWindow.closed) {
        popupWindow.close();
      }
    });
  };
  
  // const [dummyProvider, setDummyProvider] = useState("")
  // const [dummyFirstName, setDummyFirstName] = useState("")
  // const [dummyLastName, setDummyLastName] = useState("")
  // const [dummyEmail, setDummyEmail] = useState('')

  // useGoogleOneTapLogin({
  //   onError: error => {
  //     console.log(error)
  //   },
  //   onSuccess: (response) => {
  //     console.log(response);
  //     // setDummyProvider('google');
  //     // setDummyFirstName(response.given_name);
  //     // setDummyLastName(response.family_name);
  //     // setDummyEmail(response.email)
  //     setProvider('google');
  //     // setFieldValue("email", response.email);
  //     // setFieldValue("firstName", response.given_name);
  //     // setFieldValue("lastName", response.family_name);
  //     // handleSubmit();
  //   },
  //   googleAccountConfigs: {
  //     client_id: '847743167985-8r5scn91q6pbpo2m85jilhmvj3bjog14.apps.googleusercontent.com'// Your google client id here !!!
  //   },
  // });


  // useEffect(()=>{
  //   if(dummyFirstName && dummyEmail && dummyLastName && dummyProvider){
  //     // setFieldValue("email", dummyEmail);
  //     // setFieldValue("firstName", dummyFirstName);
  //     // setFieldValue("lastName", dummyLastName);
  //     // setProvider(dummyProvider);
  //   }
  // },[dummyFirstName,dummyLastName,dummyProvider,dummyEmail])
  
  return (
    <div className="signupform_container">
      <div className="social_signup">
        <h1 className="section_title bold-text">Step 2: Create Your Closest</h1>
        <br />
        {/* <div className="social_btn_container"> */}
        {/* <button className='btn dark-btn google-btn social-btn-width'><i class="fab fa-google"></i>Continue With <strong>Google</strong></button> */}
        <LoginSocialGoogle
          client_id={
            process.env.REACT_APP_GG_APP_ID ||
            "979214090074-cmcbqh2e53bjam8oba2104pp3hh3c4q2.apps.googleusercontent.com"
          }
          // onLoginStart={onLoginStart}
          redirect_uri={REDIRECT_URI}
          // scope="openid profile email"
          discoveryDocs="claims_supported"
          access_type="online"
          isOnlyGetToken
          typeResponse="accessToken"
          onResolve={({ provider, data }) => {
            //consolele.log(provider);
            //consolele.log(data);
            // setFieldValue("email", data.email);
            // setFieldValue("firstName", data.given_name);
            // setFieldValue("lastName", data.family_name);
            // setProvider(provider);

            axios
            .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${data.access_token}`, {
                headers: {
                    Authorization: `Bearer ${data.access_token}`,
                    Accept: 'application/json'
                }
            })
            .then((res) => {
                // setSpinner(true)
                console.log(res.data);
                
                setFieldValue("email", res.data.email);
                setFieldValue("firstName", res.data.given_name);
                setFieldValue("lastName", res.data.family_name);
                // formikRef.current.setFieldValue("password", "google1234");
                // formikRef.current.setFieldValue("confirmPassword", "google1234");
                setProvider("google");
                
                // formikRef.current.setErrors({});
                // formikRef.current.touched({});
                // setTimeout(()=>{
                //   handleSubmit();
                // },1000)
                
                // submitForm();
                // console.log(formikRef.current)
            })
            .catch((err) => console.log(err));
          }}
          onReject={(err) => {
            //consolele.log(err);
          }}
        >
          <GoogleLoginButton
            text="Sign up with Google"
            style={{
              minWidth: "100%",
              boxShadow: "none",
              background: "#f1f1f1",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "'Lato', sans-serif",
            }}
            activeStyle={{ background: "eee" }}
          />
        </LoginSocialGoogle>

        {/* <button className='btn dark-btn facebook-btn social-btn-width'><i class="fab fa-facebook-f"></i>Continue With <strong>Facebook</strong></button> */}
        <div style={{ margin: "1rem" }}></div>
        <LoginSocialFacebook
          appId={process.env.REACT_APP_FB_APP_ID || "1667683396935887"}
          fieldsProfile={
            "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
          }
          // onLoginStart={onLoginStart}
          // onLogoutSuccess={onLogoutSuccess}
          redirect_uri={REDIRECT_URI}
          onResolve={({ provider, data }) => {
            //consolele.log(provider);
            //consolele.log(data);
            setFieldValue("email", data.email);
            setFieldValue("firstName", data.first_name);
            setFieldValue("lastName", data.last_name);
            setProvider(provider);
          }}
          onReject={(err) => {
            //consolele.log(err);
          }}
        >
          <FacebookLoginButton
            text="Sign up with Facebook"
            style={{
              minWidth: "100%",
              boxShadow: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "'Lato', sans-serif",
            }}
          />
        </LoginSocialFacebook>
        {/* <button className='btn dark-btn microsoft-btn social-btn-width'><i class="fab fa-microsoft"></i>Continue With <strong>Microsoft</strong></button> */}
        {/* <LoginSocialAmazon
            client_id={process.env.REACT_APP_AMAZON_APP_ID || ""}
            redirect_uri={REDIRECT_URI}
            onResolve={({ provider, data }) => {
              //consolele.log(provider);
              //consolele.log(data);
            }}
            onReject={(err) => {
              //consolele.log(err);
            }}
            // onLoginStart={onLoginStart}
          >
            <AmazonLoginButton text="Sign up with Amazon" />
          </LoginSocialAmazon> */}
        {/* </div> */}
      </div>
      <div className="email_signup">
        <h2 className="bold-text lato-heading text-center">
          <br />
          OR
          <br />
          <br />
        </h2>
        <form onSubmit={handleSubmit}>
          <ScrollToFieldError />
          <div className="email_signup_form">
            <div className="personal_info_container">
              <div className="input_container">
                <label for="first_name">First Name:</label>
                <input
                  type="text"
                  id="first_name"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  required={true}
                />
                {touched.firstName && errors.firstName ? (
                  <small className="form-text text-danger">
                    {errors.firstName}
                  </small>
                ) : null}
              </div>
              <div className="input_container">
                <label for="last_name">Last Name:</label>
                <input
                  type="text"
                  id="last_name"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  required={true}
                />
                {touched.lastName && errors.lastName ? (
                  <small className="form-text text-danger">
                    {errors.lastName}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="input_container">
              <AutoComplete
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                values={values}
                handleBlur={handleBlur}
                statesList={statesList}
                setStateSelection={setStateSelection}
              />
              {touched.address && errors.address ? (
                <small className="form-text text-danger">
                  {errors.address}
                </small>
              ) : null}
            </div>
            <div className="personal_info_container">
              <div className="input_container">
                <label htmlFor="city">City*</label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                  required={true}
                />
                {touched.city && errors.city ? (
                  <small className="form-text text-danger">{errors.city}</small>
                ) : null}
              </div>

              <div className="input_container">
                <label htmlFor="zipCode">Zip Code*</label>
                <input
                  type="text"
                  id="zipCode"
                  name="zipCode"
                  onChange={handleChange}
                  value={values.zipCode}
                  onBlur={handleBlur}
                  required={true}
                />
                {touched.zipCode && errors.zipCode ? (
                  <small className="form-text text-danger">
                    {errors.zipCode}
                  </small>
                ) : null}
              </div>
            </div>
            <div className="select_container">
              <label htmlFor="state" style={{ marginBottom: ".5rem" }}>
                State*
              </label>
              <Select
                options={sList}
                id="state"
                name="state"
                isSearchable={true}
                onChange={(selectedOption) => {
                  setStateSelection(selectedOption);
                  setFieldValue("state", selectedOption.value);
                }}
                onBlur={handleBlur}
                value={stateSelection}
                placeholder={"Select State"}
                required={true}
              ></Select>
              {touched.state && errors.state ? (
                <small className="form-text text-danger">{errors.state}</small>
              ) : null}
            </div>
            <div className="input_container">
              <label for="email_address">Email Address:</label>
              <input
                type="email"
                id="email_address"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                disabled={provider === "email" ? "" : "disabled"}
              />
              {touched.email && errors.email ? (
                <small className="form-text text-danger">{errors.email}</small>
              ) : null}
            </div>
            {provider === "email" ? (
              <>
                <div className="input_container">
                  <label for="password">Password:</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                  {touched.password && errors.password ? (
                    <small className="form-text text-danger">
                      {errors.password}
                    </small>
                  ) : null}
                </div>
                <div className="input_container">
                  <label for="confirm_password">Confirm Password:</label>
                  <input
                    type="password"
                    id="confirm_password"
                    name="confirmPassword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                  />
                  {touched.confirmPassword && errors.confirmPassword ? (
                    <small className="form-text text-danger">
                      {errors.confirmPassword}
                    </small>
                  ) : null}
                </div>
              </>
            ) : (
              ""
            )}

            <div className="price_container">
              <h3 style={{ margin: ".5rem 0" }}>
                <span>
                  Amount: $
                  {membership === "monthly"
                    ? 20
                    : membership === "trial"
                    ? 0
                    : 200}
                </span>
                <br />
                <span>
                  Discount: ${membership === "trial" ? 0 : discount}
                  {discountPercent ? " (" + discountPercent + "% OFF)" : ""}
                </span>
                <hr />
                <span>
                  Total: $
                  {membership === "monthly"
                    ? discount
                      ? (20 - discount).toFixed(2)
                      : 20
                    : membership === "trial"
                    ? 0
                    : discount
                    ? Number(200 - discount.toFixed(2)).toFixed(2)
                    : 200}
                </span>
              </h3>
              {membership != "trial" ? (
                <>
                  <img
                    src={stripebadge2}
                    alt="stripe badge cards"
                    width={"60%"}
                    style={{
                      borderRadius: "5px",
                      height: "60%",
                      display: "flex",
                      alignSelf: "center",
                    }}
                  />
                </>
              ) : (
                ""
              )}
            </div>
            <div>
              {/* Apply coupen code input field and also button */}
              {membership != "trial" ? (
                <div
                  className="coupen_container"
                  style={{ alignItems: "center" }}
                >
                  <div className="input_container">
                    <label for="email_address">Gift Card/Discount Code:</label>
                    <input
                      type="text"
                      id="coupen_code"
                      name="coupen"
                      onChange={(e) =>
                        setCoupen(e.target.value.toLocaleUpperCase())
                      }
                      value={coupen}
                      disabled={disableCoupen}
                    />
                  </div>
                  {!disableCoupen ? (
                    <button
                      type="button"
                      onClick={() => checkCoupen(coupen)}
                      className="btn dark-btn coupen_btn"
                      disabled={status === "loading" ? true : false}
                    >
                      {status === "loading" ? "Please Wait" : "Apply Gift Card"}
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        setDisableCoupen(false);
                        setCoupen("");
                        setDiscountPercent(0);
                        setDiscount(0);
                        setCoupenApplied(null);
                      }}
                      className="btn dark-btn coupen_btn"
                      disabled={status === "loading" ? true : false}
                    >
                      {status === "loading" ? "Please Wait" : "Clear Gift Card"}
                    </button>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            {membership != "trial" ? (
              <>
                <div style={{ marginTop: "21px" }}>
                  <Elements stripe={stripePromise}>
                    <InjectedCheckoutForm
                      setTc={setTc}
                      tc={tc}
                      submitForm={submitForm}
                      formRef={formRef}
                      setId={setId}
                      spinner={spinner}
                      setSpinner={setSpinner}
                      errors={errors}
                    />
                  </Elements>
                </div>
              </>
            ) : (
              <>
                <div
                  className="aggrement_container"
                  style={{ marginTop: "21px" }}
                >
                  <input
                    type="checkbox"
                    className="tc_input"
                    style={{ marginLeft: ".5rem" }}
                    onChange={() => setTc((prev) => !prev)}
                    checked={tc}
                  />
                  <label>
                    &nbsp;I agree to{" "}
                    <span
                      className="tc"
                      style={{ cursor: "pointer" }}
                      onClick={() => openPopup()}
                      // onClick={() => setIsOpen((prev) => !prev)}
                    >
                      Terms and Conditions
                    </span>
                  </label>
                </div>
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="btn dark-btn"
                  disabled={
                    status === "loading" || spinner === true ? true : false
                  }
                >
                  {status === "loading" || spinner === true
                    ? "Please Wait"
                    : "Become a Member"}
                </button>
                {/* <Modal
                  ariaHideApp={false}
                  isOpen={modalIsOpen}
                  onRequestClose={() => setIsOpen(false)}
                  style={{
                    overlay: {
                      background: "rgba(0,0,0,0.5)",
                    },
                    content: {
                      maxWidth: "80%",
                      maxHeight: "auto",
                      margin: "auto",
                      position: "static",
                    },
                  }}
                  contentLabel="Example Modal"
                >
                  <div
                    className="close_container"
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                  </div>

                  <div className="terms-modal-con">
                    <div className="terms-modal-wrapper">
                      <h3>Terms and Conditions</h3>
                      <p style={{ textAlign: "left" }} className="h5">
                        Closest Closet, LLC. (“Closest Closet,” “we,” “us,”
                        and/or “our”) provides its services (described below) to
                        you through its mobile applications and other software
                        made available by Closest Closet (“Software”) and its
                        website located at{" "}
                        <a
                          href="https://www.ClosestCloset.com"
                          style={{ color: "#F26654", lineBreak: "anywhere" }}
                        >
                          https://www.ClosestCloset.com
                        </a>{" "}
                        (the “Site”) (collectively, such services, Software and
                        the Site, collectively, the “Service(s)”), subject to
                        the following Terms of Service (as amended from time to
                        time, these “Terms of Service”). We reserve the right,
                        at our sole discretion, to change or modify portions of
                        these Terms of Service at any time without further
                        notice. You should periodically visit this page to
                        review the current Terms of Service so you are aware of
                        any revision to which you are bound. If we do this, we
                        will post the changes to these Terms of Service on this
                        page and will indicate at the top of this page the date
                        these terms were last revised. We will also notify you,
                        either through the Services user interface, in an email
                        notification or through other reasonable means. Any such
                        changes will become effective for existing users no
                        earlier than ten (10) days after they are posted, except
                        that changes addressing new functions of the Services or
                        changes made for legal reasons will be effective
                        immediately. Your continued use of the Service after the
                        date any such changes become effective constitutes your
                        acceptance of the new Terms of Service. If you do not
                        agree to abide by these or any future Terms of Service,
                        do not use or access (or continue to use or access) the
                        Service.
                      </p>
                      <br />
                      <strong>Hanger Price will be set by CC*</strong>
                      <br />
                      <br />
                    </div>
                  </div>
                </Modal> */}
              </>
            )}

            {/* <button className='btn dark-btn' disabled={status==="loading"?true:false} >{status==="loading"?"Please Wait":"Become a Member"}</button> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupForm;