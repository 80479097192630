import React from "react";
import "./FutureOfFashion.css";
import hanger from "../../assets/images/spinner-hanger.png";
import closestClosetBag from "../../assets/images/closestClosetBag.png";
import shop from "../../assets/images/shop.png";
import repeatImg from "../../assets/images/repeat_img.png";
import repeat from "../../assets/images/repeat2.png";

const FutureOfFashion = () => {
  return (
    <section className="future-of-fashion-con">
      <h2 className="large-text bold-text heading text-center">
        The Future of Fashion is Circular
      </h2>
      <h3 className="h3 desc lato-desc">
        Curate your individual style without expense, guilt, or waste.
      </h3>
      <div className="fashion-card-con">
        <div className="card">
          <div className="img-con">
            {/* <PiCoatHanger size={"10em"} color="var(--primary-color)" /> */}
            <img alt="hanger_icon" src={hanger} />
          </div>
          <div className="h1 bold-text text-uppercase primary-text">Hang</div>
          <p
            className="h5 text-center px-1"
            style={{ marginTop: "1rem", minHeight: "100px" }}
          >
            Create a closet by hanging up items you are no longer wearing. Each
            item you hang earns you hanger credits.
          </p>
        </div>
        <div className="card">
          <div className="img-con">
            <img alt="closest_closet_bag_icon" src={closestClosetBag} />
          </div>
          <div className="h1 bold-text text-uppercase primary-text">Shop</div>
          <p
            className="h5 text-center px-1"
            style={{ marginTop: "1rem", minHeight: "100px" }}
          >
            Shop other members’ closets by using your hangers as currency.
          </p>
        </div>
        <div className="card">
          <div className="img-con">
            <img alt="shop_icon" src={shop} />
          </div>
          <div className="h1 bold-text text-uppercase primary-text">Wear</div>
          <p
            className="h5 text-center px-1"
            style={{ marginTop: "1rem", minHeight: "100px" }}
          >
            Show off your fabulous new finds until you're on the hunt for
            something new or make them wardrobe staples.
          </p>
        </div>
        <div className="card">
          <div className="img-con">
            <img alt="repeat_icon" src={repeat} />
          </div>
          <div className="h1 bold-text text-uppercase primary-text">Repeat</div>
          <p
            className="h5 text-center px-1"
            style={{ marginTop: "1rem", minHeight: "100px" }}
          >
            Hang items from your closet you’re not wearing to earn credits and
            shop for something new.
          </p>
        </div>
      </div>
      <div className="repeat-icon">
        <img alt="repeat_arrow" src={repeatImg} />
      </div>
    </section>
  );
};

export default FutureOfFashion;
