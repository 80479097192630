import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  getProducts,
  resetUpdateStatus,
  deleteProduct,
  unbanProduct,
  transferAdminProduct,
} from "../../../components/StateSlices/adminSlice";
import "./Products.css";
import Spinner1 from "../../../components/Spinner1/Spinner1";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaFileDownload, FaUndo } from "react-icons/fa";
import { BiDollar } from "react-icons/bi";
import hanger from "../../../assets/images/hanger.svg";
import Pagination from "../../../components/Pagination/Pagination";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";

import "react-toastify/dist/ReactToastify.css";
import { MdOutlineFileUpload } from "react-icons/md";
import { transferProduct } from "../../../components/StateSlices/transferProductSlice";
import {
  getDataExport,
  getProductDataExport,
} from "../../../components/StateSlices/adminDataExportSlice";

const Products = ({ tab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();

  const toastOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const {
    allUserStatus,
    allProductsInfo,
    allUserError,
    totalCount,
    updateUserStatus,
  } = useSelector((state) => state.admin);

  const [pageNumber, setPageNumber] = useState(
    location.search.slice(-1)
      ? location.search.slice(location.search.indexOf("=") + 1) - 1
      : 0
  );
  const [productSearchVal, setProductSearchVal] = useState(null);
  const [sorting, setSorting] = useState("rating");

  useEffect(() => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      // console.log(pageNumber);
      // if (location.search.slice(-1)) {
      // if (
      //   location.search.slice(location.search.indexOf("=") + 1) - 1 ==
      //   pageNumber
      // ) {
      //   dispatch(
      //     getProductDataExport({
      //       token,
      //       p: location.search.slice(location.search.indexOf("=") + 1) - 1,
      //       search: productSearchVal,
      //       status: tab === "ban" ? true : false,
      //       sort: sorting,
      //       report: tab === "report" ? true : false,
      //       purchased: tab === "purchased" ? true : false,
      //     })
      //   );
      //   window.scrollTo(0, 0);
      // } else {
      // setPageNumber(
      //   location.search.slice(location.search.indexOf("=") + 1) - 1
      // );
      // }
      // } else {
      // console.log("This");
      dispatch(
        getProducts({
          token,
          p: pageNumber,
          search: productSearchVal,
          status: tab === "ban" ? true : false,
          sort: sorting,
          report: tab === "report" ? true : false,
          purchased: tab === "purchased" ? true : false,
        })
      );
      window.scrollTo(0, 0);
      // }
    } else {
      navigate("/404", { replace: true });
    }
  }, [pageNumber, location?.search?.slice(-1), tab, sorting]);

  const resetSearch = () => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      // setPageNumber(0)
      navigate(`/admin/products?page=1`, { replace: true });
      if (location.search.slice(location.search.indexOf("=") + 1) - 1 == 0) {
        dispatch(
          getProducts({
            token,
            p: 0,
            status: tab === "ban" ? true : false,
            sort: sorting,
            report: tab === "report" ? true : false,
            purchased: tab === "purchased" ? true : false,
          })
        );
      }
      //
    } else {
      navigate("/404", { replace: true });
    }
  };

  const deleteUserWar = (id) => {
    swal({
      title: "Delete Product",
      text: "Are you sure you want to delete and remove this item from the site?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      content: {
        element: "input",
        attributes: {
          placeholder: "Reason for Ban",
          type: "text",
          // require:true
        },
      },
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value.trim() !== "") {
            resolve();
          } else {
            resolve("Please enter a reason for banning the product");
          }
        });
      },
    }).then((willReactive) => {
      // console.log(willReactive);
      if (willReactive !== null) {
        let token = localStorage.getItem("clttoken");
        if (token) {
          dispatch(
            deleteProduct({
              token,
              id,
              p: pageNumber,
              search: productSearchVal,
              reason: willReactive,
              status: tab === "ban" ? true : false,
              sort: sorting,
              report: tab === "report" ? true : false,
              purchased: tab === "purchased" ? true : false,
            })
          );
        } else {
          navigate("/404", { replace: true });
        }
      }
    });
  };

  const unbanProductWar = (id) => {
    swal({
      title: "Unban Product",
      text: "Once Unbanned, Product will be available for users",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      // console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        if (token) {
          dispatch(
            unbanProduct({
              token,
              id,
              p: pageNumber,
              search: productSearchVal,
              status: tab === "ban" ? true : false,
              sort: sorting,
              report: tab === "report" ? true : false,
              purchased: tab === "purchased" ? true : false,
            })
          );
        } else {
          navigate("/404", { replace: true });
        }
      }
    });
  };

  const transferProduct = (id, checkoutId) => {
    swal({
      title: "Transfer Product",
      text: "Transfer product to Community Closet.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willReactive) => {
      // console.log(willReactive);
      if (willReactive) {
        let token = localStorage.getItem("clttoken");
        if (token) {
          dispatch(
            transferAdminProduct({
              token,
              id,
              p: pageNumber,
              search: productSearchVal,
              status: tab === "ban" ? true : false,
              sort: sorting,
              report: tab === "report" ? true : false,
              purchased: tab === "purchased" ? true : false,
              checkoutId,
            })
          );
        } else {
          navigate("/404", { replace: true });
        }
      }
    });
  };

  if (updateUserStatus === "succeeded") {
    toast.success(
      tab === "ban"
        ? "Product Unbanned Successfully"
        : "Product Deleted Successfully",
      toastOption
    );
    dispatch(resetUpdateStatus());
  }

  const downloadProductCsv = () => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(
        getProductDataExport({
          token,
          p: location.search.slice(location.search.indexOf("=") + 1) - 1,
          search: productSearchVal,
          status: tab === "ban" ? true : false,
          sort: sorting,
          report: tab === "report" ? true : false,
          purchased: tab === "purchased" ? true : false,
        })
      );
      toast.info("Please Wait for a While", toastOption);
    }
  };

  return (
    <>
      <div className="adm-prod-con">
        <div className="adm-prod-wrapper">
          <div className="products-header-admin">
            <div
              className="h2 background-text bold-text heading-title"
              style={{ minWidth: "30%" }}
            >
              Manage Products
            </div>
            <div className="sortProducts">
              <div className="productsSorter">
                <br />
                <a className="sortProductsDropdown" href="javascript:void(0);">
                  Sort by <i class="fa-solid fa-angle-down"></i>
                </a>
                <div className="products-drop-down">
                  <a
                    style={sorting === "rating" ? { color: "#ff6e00" } : {}}
                    href="javascript:void(0);"
                    onClick={() => {
                      setSorting("rating");
                    }}
                  >
                    Rating: High to Low
                  </a>
                  <a
                    style={
                      sorting === "rating-desc" ? { color: "#ff6e00" } : {}
                    }
                    href="javascript:void(0);"
                    onClick={() => {
                      setSorting("rating-desc");
                    }}
                  >
                    Rating: Low to High
                  </a>
                  <a
                    style={sorting === "report" ? { color: "#ff6e00" } : {}}
                    href="javascript:void(0);"
                    onClick={() => {
                      setSorting("report");
                    }}
                  >
                    Most Reported
                  </a>
                  <a
                    style={
                      sorting === "report-desc" ? { color: "#ff6e00" } : {}
                    }
                    href="javascript:void(0);"
                    onClick={() => {
                      setSorting("report-desc");
                    }}
                  >
                    Least Reported
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="input_container search_closet"
            style={{ marginBottom: "2rem" }}
          >
            <form
              className="input_container_wrapper"
              onSubmit={(e) => {
                e.preventDefault();
                let token = localStorage.getItem("clttoken");
                if (token) {
                  // setPageNumber(0)
                  navigate(`/admin/products?page=1`, { replace: true });
                  if (
                    location.search.slice(-1) &&
                    location.search.slice(location.search.indexOf("=") + 1) -
                      1 ==
                      0
                  ) {
                    dispatch(
                      getProducts({
                        token,
                        p: 0,
                        search: productSearchVal,
                        status: tab === "ban" ? true : false,
                        sort: sorting,
                        report: tab === "report" ? true : false,
                        purchased: tab === "purchased" ? true : false,
                      })
                    );
                  }
                } else {
                  navigate("/404", { replace: true });
                }
              }}
              style={{ display: "flex", flex: 1, alignItems: "center" }}
            >
              <div style={{ display: "flex", flex: 1 }}>
                <input
                  type="text"
                  id="userSearch"
                  className="mt-0"
                  name="userSearch"
                  onChange={(e) => setProductSearchVal(e.target.value)}
                  value={productSearchVal}
                  placeholder="Enter Product Name or Anything..."
                  style={{
                    marginTop: "0px !important",
                    fontFamily: "inherit",
                    padding: "10px 12px",
                    borderRadius: "5px 0 0 5px",
                  }}
                />
                <button
                  type="submit"
                  className="btn"
                  style={{
                    borderRadius: "0 5px 5px 0",
                    padding: "6px 12px",
                    marginTop: "5px",
                  }}
                >
                  <i class="fas fa-search fa-lg"></i>
                </button>
              </div>
              <div>
                {productSearchVal && (
                  <span
                    className="small-text"
                    style={{
                      marginRight: 0,
                      marginLeft: "10px",
                      cursor: "pointer",
                      color: "var(--background-color)",
                      fontSize: "13px",
                      fontFamily: "LATO",
                      textTransform: "uppercase",
                      fontWeight: "bold",
                    }}
                    onClick={() => {
                      resetSearch();
                      setProductSearchVal("");
                    }}
                  >
                    Reset <FaUndo style={{ fontSize: "11px" }} />
                  </span>
                )}
              </div>
              <button
                style={{
                  padding: "5px 10px",
                  marginLeft: "1rem",
                  marginTop: "5px",
                }}
                type="button"
                className="btn"
                onClick={downloadProductCsv}
                title="Download CSV File of All Products"
              >
                <FaFileDownload size={"1.7em"} />
              </button>
            </form>
          </div>
          {(tab === "active" || tab === "report" || tab === "purchased") && (
            <div class="products products-table">
              {allUserStatus === "loading" ? (
                <Spinner1 />
              ) : (
                <>
                  {allProductsInfo && allProductsInfo.length == 0 ? (
                    <div
                      style={{ marginTop: "1rem", marginBottom: "4rem" }}
                      className="no_item"
                    >
                      <h1 className="h1" style={{ marginTop: "1rem" }}>
                        No Products Found
                      </h1>
                    </div>
                  ) : (
                    <>
                      {allProductsInfo &&
                        allProductsInfo.length > 0 &&
                        allProductsInfo.map((prod, index) => {
                          return (
                            <div
                              key={index}
                              style={
                                prod.productArchive === true
                                  ? { background: "#dddddd" }
                                  : {}
                              }
                              className={
                                prod.productArchive === true
                                  ? "archived_ribbon left_ribbon product"
                                  : " product"
                              }
                              data-ribbon="Archived"
                            >
                              <div class="product-img">
                                <LazyLoadImage
                                  src={
                                    prod.productImage[0]
                                      ? prod.productImage[0]
                                      : "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"
                                  }
                                  alt="FP1"
                                  effect="blur"
                                  style={{
                                    width: "150px",
                                    minHeight: "150px",
                                    maxHeight: "150px",
                                    objectFit: "contain",
                                  }}
                                />
                                {/* <img src="https://placehold.it/500x500" /> */}
                              </div>
                              <div class="product-content">
                                <h3 className="background-text">
                                  {prod.productName}
                                </h3>
                                <div className="product-details-row">
                                  <div className="col">
                                    <em
                                      style={{
                                        margin: "8px 0",
                                        color: "var(--dark-grey-color)",
                                      }}
                                    >
                                      {prod.closetName}{" "}
                                      {prod.buyerEmail
                                        ? `(${prod.buyerEmail})`
                                        : ""}
                                    </em>
                                    <hr className="product-hr" />
                                    <div className="bold-text h6">
                                      <span class="product-text price">
                                        $&nbsp;{prod.productPrice}
                                      </span>
                                      <span
                                        style={{
                                          margin: "auto 1rem",
                                          color: "var(--background-color)",
                                        }}
                                      >
                                        |
                                      </span>
                                      <span class="product-text genre">
                                        <img
                                          style={{ height: "14px" }}
                                          src={hanger}
                                          alt="hanger icon"
                                        />{" "}
                                        {prod.productHanger}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="h6">
                                      <span className="bold-text background-text">
                                        Brand :{" "}
                                      </span>
                                      <span>{prod.productStats?.brand}</span>
                                    </div>

                                    <div className="h6">
                                      <span className="bold-text background-text">
                                        Category :{" "}
                                      </span>
                                      <span>
                                        {prod.productStats?.category + ","}
                                      </span>
                                    </div>

                                    <div className="h6">
                                      <span className="bold-text background-text">
                                        Condition :{" "}
                                      </span>
                                      <span>
                                        {prod.productStats?.condition}
                                      </span>
                                    </div>

                                    <div className="h6">
                                      <span className="bold-text background-text">
                                        Stock :{" "}
                                      </span>
                                      <span>{prod.productStock}</span>
                                    </div>

                                    <div className="h6">
                                      <span className="bold-text background-text">
                                        Size :{" "}
                                      </span>{" "}
                                      <span>{prod.productStats?.size}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="product-operations">
                                <div
                                  className="action_icon"
                                  style={{
                                    width: "100%",
                                    margin: "auto",
                                  }}
                                >
                                  {tab === "purchased" ? (
                                    <div
                                      className="btn"
                                      onClick={() => {
                                        transferProduct(
                                          prod.productId,
                                          prod._id
                                        );
                                      }}
                                    >
                                      <MdOutlineFileUpload size={"1.4em"} />
                                    </div>
                                  ) : (
                                    <>
                                      <Link
                                        to={`/product/${encodeURIComponent(
                                          prod.productStats.brand
                                        )}-${encodeURIComponent(
                                          prod.productName
                                        )}/${prod._id}`}
                                        state={{ id: prod._id }}
                                        style={{ textDecoration: "none" }}
                                      >
                                        <div className="btn">
                                          <i class="fas fa-eye"></i>
                                        </div>
                                      </Link>
                                      <Link
                                      
                                       to={`/editproduct/${encodeURIComponent(
                                        prod.productStats.brand
                                      )}-${encodeURIComponent(
                                        prod.productName
                                      )}`}
                                        state={{ id: prod._id, from: "admin" }}
                                        style={{ textDecoration: "none" }}
                                      >
                                        <div className="btn" 
                                        // onClick={() => {
                                        //   const url = `/editproduct/${encodeURIComponent(
                                        //     prod?.productStats?.brand
                                        //   )}-${encodeURIComponent(
                                        //     prod?.productName
                                        //   )}`;
                                        //   const state = {
                                        //     id: prod?._id,
                                        //     from: "admin",
                                        //   };
                                        //   localStorage.setItem("editProductState", JSON.stringify(state));
                                        //   const newTab = window.open(
                                        //     url,
                                        //     "_blank"
                                        //   );
                                        // }}
                                        >
                                          <i class="fas fa-pen"></i>
                                        </div>
                                      </Link> 
                                      {/* <Link to={"/my-closet"}> */}
                                      <div
                                        className="btn"
                                        onClick={() => {
                                          deleteUserWar(prod._id);
                                        }}
                                      >
                                        <i class="fas fa-trash"></i>
                                      </div>
                                      {/* </Link> */}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </>
                  )}
                </>
              )}
            </div>
          )}
          {tab == "ban" && (
            <div class="products products-table">
              {allUserStatus === "loading" ? (
                <Spinner1 />
              ) : (
                <>
                  {allProductsInfo && allProductsInfo.length == 0 ? (
                    <div
                      style={{ marginTop: "1rem", marginBottom: "4rem" }}
                      className="no_item"
                    >
                      <h1 className="h1" style={{ marginTop: "1rem" }}>
                        No Products Found
                      </h1>
                    </div>
                  ) : (
                    <>
                      {allProductsInfo &&
                        allProductsInfo.length > 0 &&
                        allProductsInfo.map((prod, index) => {
                          return (
                            <div class="product" key={index}>
                              <div class="product-img">
                                <LazyLoadImage
                                  src={
                                    prod.productImage[0]
                                      ? prod.productImage[0]
                                      : "https://propertywiselaunceston.com.au/wp-content/themes/property-wise/images/no-image.png"
                                  }
                                  alt="FP1"
                                  effect="blur"
                                  style={{
                                    width: "150px",
                                    minHeight: "150px",
                                    maxHeight: "150px",
                                  }}
                                />
                                {/* <img src="https://placehold.it/500x500" /> */}
                              </div>
                              <div class="product-content">
                                <h3 className="background-text">
                                  {prod.productName}
                                </h3>
                                <div className="product-details-row">
                                  <div className="col">
                                    <em
                                      style={{
                                        margin: "8px 0",
                                        color: "var(--dark-grey-color)",
                                      }}
                                    >
                                      {prod.closetName}
                                    </em>
                                    <hr className="product-hr" />
                                    <div className="bold-text h6">
                                      <span class="product-text price">
                                        $&nbsp;{prod.productPrice}
                                      </span>
                                      <span
                                        style={{
                                          margin: "auto 1rem",
                                          color: "var(--background-color)",
                                        }}
                                      >
                                        |
                                      </span>
                                      <span class="product-text genre">
                                        <img
                                          style={{ height: "14px" }}
                                          src={hanger}
                                          alt="hanger icon"
                                        />{" "}
                                        {prod.productHanger}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="h6">
                                      <span className="bold-text background-text">
                                        Brand :{" "}
                                      </span>
                                      <span>{prod.productStats?.brand}</span>
                                    </div>

                                    <div className="h6">
                                      <span className="bold-text background-text">
                                        Category :{" "}
                                      </span>
                                      <span>
                                        {prod.productStats?.category + ","}
                                      </span>
                                    </div>

                                    <div className="h6">
                                      <span className="bold-text background-text">
                                        Condition :{" "}
                                      </span>
                                      <span>
                                        {prod.productStats?.condition}
                                      </span>
                                    </div>

                                    <div className="h6">
                                      <span className="bold-text background-text">
                                        Stock :{" "}
                                      </span>
                                      <span>{prod.productStock}</span>
                                    </div>

                                    <div className="h6">
                                      <span className="bold-text background-text">
                                        Size :{" "}
                                      </span>{" "}
                                      <span>{prod.productStats?.size}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="product-operations">
                                <div
                                  className="action_icon"
                                  style={{
                                    width: "100%",
                                    margin: "auto",
                                  }}
                                >
                                  {/* <Link
                                    to={`/product/${encodeURIComponent(
                                      prod.productStats.brand
                                    )}-${encodeURIComponent(prod.productName)}`}
                                    state={{ id: prod._id }}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <div className="btn">Unban</div>
                                  </Link>
                                  <Link
                                    to={`/editproduct/${encodeURIComponent(
                                      prod.productStats.brand
                                    )}-${encodeURIComponent(prod.productName)}`}
                                    state={{ id: prod._id }}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <div className="btn">
                                      <i class="fas fa-pen"></i>
                                    </div>
                                  </Link> */}
                                  {/* <Link to={"/my-closet"}> */}
                                  <div
                                    className="btn"
                                    onClick={() => {
                                      unbanProductWar(prod._id);
                                    }}
                                  >
                                    <i className="fas fa-trash-restore-alt"></i>
                                  </div>
                                  {/* </Link> */}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </>
                  )}
                </>
              )}
            </div>
          )}
          {totalCount > 20 && (
            <Pagination
              pageCount={Math.ceil(totalCount / 20)}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Products;
