import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { getCMSContent, resetCMS } from "../components/StateSlices/cmsSlice";
import Spinner from "../components/Spinner/Spinner";
import "./CMSFront.css";
// import "./HowItWorks.css"
import "./PrivacyPolicy.css";
import cimg from "../assets/images/hiwcover.png";
import ScrollAnimation from "react-animate-on-scroll";

const CMSFront = () => {
  const { pageURL } = useParams();
  const navigate = useNavigate();
  const { cmsStatus, cmsInfo, cmsError, cmsFetchStatus, cmsMessage } =
    useSelector((state) => state.cms);
  const dispatch = useDispatch();
  const [content, setContent] = useState(null);

  useEffect(() => {
    if (pageURL) {
      // console.log(pageURL);
      dispatch(getCMSContent({ id: pageURL }));
    } else {
      navigate("/404", { replace: true });
    }
  }, []);

  useEffect(() => {
    if (cmsInfo) {
      setContent(cmsInfo);
      dispatch(resetCMS());
    }
  }, [cmsInfo]);

  if (cmsError) {
    dispatch(resetCMS());
    navigate("/404", { replace: true });
  }

  return (
    <ScrollAnimation animateOnce={true} animateIn="fadeIn">
      <div
        className="page-container"
        style={{ position: "relative", minHeight: "39vh" }}
      >
        {!content ? (
          <Spinner />
        ) : pageURL === "terms-conditions" ? (
          <div
            className="page-container privacy_policy_container"
            style={{ minHeight: "40vh" }}
          >
            <h1 className="section_title">{content.bannerText}</h1>
            <div className="policy_container">
              <div dangerouslySetInnerHTML={{ __html: content.pageContent }} />
            </div>
          </div>
        ) : (
          <div className="cms_front_container">
            <div className="cms_front_banner_container">
              <img src={content.pageBanner} alt="Banner" />
              {content.bannerText && (
                <div className="banner_text">{content.bannerText}</div>
              )}
            </div>
            <div
              className="ck-content cms_front_content_container"
              dangerouslySetInnerHTML={{ __html: content.pageContent }}
            />
          </div>
        )}
      </div>
    </ScrollAnimation>
  );
};

export default CMSFront;
