import React, { useEffect, useState } from "react";
// import { ChatEngine, ChatCard, ChatHeader, ChatList } from "react-chat-engine";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./ChatSupport.css";
import {
  useMultiChatLogic,
  MultiChatSocket,
  MultiChatWindow,
  ChatCard,
  ChatList,
  ChatHeader,
  ChatForm,
  ChatFeed,
  MessageList,
  MessageForm,
} from "react-chat-engine-advanced";
// import "../SupportEngine/index.css";
import {
  getuserInfo,
  reset,
} from "../../../components/StateSlices/getuserSlice";
import { PrettyChatWindow } from "react-chat-engine-pretty";
import ChatCardCustom from "./ChatCardCustom";
import Spinner1 from "../../Spinner1/Spinner1";
import {
  addChatHistory,
  deleteChatHistory,
  resetChatHistory,
} from "../../StateSlices/chatHistorySlice";
import Spinner from "../../Spinner/Spinner";
import { Helmet } from "react-helmet";

const SupportAdmin = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, userInfo, error, profile } = useSelector(
    (state) => state.user
  );
  const { chatHistoryInfo } = useSelector((state) => state.chathistory);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [activeeChat, setActiveeChat] = useState(null);
  const [sellerEmailFromUrl, setSellerEmailFromUrl] = useState("")
  // //console.log(chatProps)

  // //console.log(userInfo);

  useEffect(() => {
    let token = localStorage.getItem("clttoken");
    if (token) {
      dispatch(getuserInfo({ token: token }));
      window.scrollTo(0, 0);
    } else {
      navigate("/login", { replace: true });
    }
  }, []);

  useEffect(()=>{
      // Get the current URL
    let queryParams = new URLSearchParams(window.location.search);
    let emailParamVal = queryParams.get("email");
    //console.log(emailParamVal);


    // Regular expression pattern to match email addresses
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    console.log(emailParamVal)
    // Extract email addresses from the URL using the pattern
    if (emailParamVal) {
      const matches = emailParamVal.match(emailPattern);

      // Display the extracted email addresses
      if (matches) {
        setSellerEmailFromUrl(matches[0]);
        //console.log("kcinside ", emailParamVal);
      }
    }
  },[])

  useEffect(() => {
    //console.log(chatProps.activeChatId, activeeChat, location.state);
    if (
      userInfo &&
      sellerEmailFromUrl === "" &&
      location?.state?.sellerEmail === undefined &&
      chatProps.activeChatId != activeeChat
    ) {
      //console.log("Yesss");
      setLoading(true);
      // getOrCreateChat(setActiveChat);
    }
  }, [userInfo]);

  useEffect(() => {
    if (location.state || sellerEmailFromUrl) {
      //console.log(userInfo,location.state.sellerEmail, "From UseEffect")
      if (userInfo && location.state && location.state.sellerEmail) {
        //console.log(location.state.sellerEmail);
        //console.log("Yesss222");
        setLoading(true);
        getOrCreateChat(setActiveChat);
      } else if (sellerEmailFromUrl !== "") {
        setLoading(true);
        getOrCreateChat(setActiveChat);
      }
    }
  }, [location?.state?.sellerEmail || sellerEmailFromUrl, userInfo]);

  useEffect(() => {
    if (
      userInfo &&
      userInfo.userChatHistoryId &&
      userInfo.userChatHistoryId.chatUnreadCount > 0
    ) {
      let findChatId = userInfo.userChatHistoryId.unseenChat.some(
        (chat) => chat.chatId == activeeChat
      );
      //console.log(findChatId);
      if (findChatId) {
        let token = localStorage.getItem("clttoken");
        dispatch(deleteChatHistory({ token, values: { chatId: activeeChat } }));
      }
    }
  }, [activeeChat]);

  if (chatHistoryInfo === "Succesfully Removed the entry") {
    let token = localStorage.getItem("clttoken");
    dispatch(getuserInfo({ token: token }));
    dispatch(resetChatHistory());
  }

  let setActiveChat = (response) => {
    //console.log("response", response);
    //console.log(chatProps);
    chatProps.setMessages(response);
    // chatProps.setActiveChatId(response.id);
    //console.log("Donee");
    // setLoading(false);
  };

  const addToUnseenChat = async (chatId, receiver) => {
    let token = localStorage.getItem("clttoken");
    dispatch(addChatHistory({ token, values: { chatId, receiver } }));
  };

  function getOrCreateChat(callback) {
    console.log("inside ", userInfo?.userDetailsId?.userEmail);
    axios
      .put(
        "https://chatengine.eastus.cloudapp.azure.com/chats/",
        {
          usernames: [
            (location.state && location.state.sellerEmail) ||
              (sellerEmailFromUrl && sellerEmailFromUrl),
            userInfo?.userDetailsId?.userEmail,
          ],
          is_direct_chat: true,
        },
        {
          headers: {
            "Project-ID": process.env.REACT_APP_CE_PROJECT_ID,
            "User-Name": userInfo?.userDetailsId?.userEmail,
            "User-Secret": userInfo?.userDetailsId?.userEmail,
          },
        }
      )
      .then((r) => {
        setActiveeChat(r.data.id);
        if(new Date(new Date(r.data.created).getTime() + 5000).getTime() >= new Date().getTime()){
          console.log("RELOADING")
          window.location.reload();
        }
        getMessages(r.data, callback);
        console.log("Message Recived", r)
      });
  }

  function getMessages(data, callback) {
    axios
      .get("https://chatengine.eastus.cloudapp.azure.com/chats/" + data.id + "/messages/", {
        headers: {
          "Project-ID": process.env.REACT_APP_CE_PROJECT_ID,
          "User-Name": userInfo?.userDetailsId?.userEmail,
          "User-Secret": userInfo?.userDetailsId?.userEmail,
        },
      })
      .then((r) => {
        callback(r.data);
      });
  }

  const projectId = process.env.REACT_APP_CE_PROJECT_ID;
  const username = userInfo && userInfo.userDetailsId.userEmail;
  const secret = userInfo && userInfo.userDetailsId.userEmail;

  const chatProps = useMultiChatLogic(projectId, username, secret, "https://chatengine.eastus.cloudapp.azure.com");

  useEffect(() => {
    if (chatProps) {
      //console.log("cominnng",activeeChat,chatProps.activeChatId,location?.state?.sellerEmail)
      if (chatProps.activeChatId == activeeChat) {
        // //console.log(chatProps.activeChatId)
        // //console.log(activeeChat)
        setLoading(false);
      } else if (
        activeeChat == -1 &&
        location?.state?.sellerEmail == undefined
      ) {
        //console.log(location?.state?.sellerEmail);
        //console.log("JU<PPPP");
        setActiveeChat(chatProps.activeChatId);
      } else if (chatProps.activeChatId !== undefined && activeeChat !== -1) {
        //console.log(activeeChat);
        chatProps.setActiveChatId(activeeChat);
      }
    }
  }, [chatProps, activeeChat]);

  //console.log(chatProps, activeeChat);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="This page is for chatting portal to the closestcloset website"
        />
        <meta property="og:type" content="product" />
        <meta property="og:title" content={`Closest Closet - How It Works`} />
        <meta
          property="og:description"
          content={
            "This page is for chatting portal to the closestcloset website"
          }
        />
        <meta
          property="og:url"
          content={`https://www.closestcloset.com/${window.location.pathname}`}
        />
        <meta
          property="og:image"
          content={
            "https://res.cloudinary.com/closet-closest/image/upload/v1703952666/Untitled_design_xekf33.png"
          }
        />
        <meta property="og:site_name" content="ClosestCloset" />
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Closest Closet - How It Works`} />
        <meta
          name="twitter:description"
          content={
            "This page is for chatting portal to the closestcloset website"
          }
        />
        <meta
          name="twitter:image"
          content={
            "https://res.cloudinary.com/closet-closest/image/upload/v1703952666/Untitled_design_xekf33.png"
          }
        />
      </Helmet>
      <div className="chat-engine-wrap" style={{ position: "relative" }}>
        {loading || !userInfo ? <Spinner /> : ""}
        {userInfo && (
          <>
            <MultiChatWindow
              {...chatProps}
              // onChatLoaderShow={(data) => /*console.log(data)*/}
              style={{
                height: "80vh",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
                borderRadius: ".5px solid #ddd",
                border: "1px solid #ddd",
              }}
              isChatFeedLoading
              isChatListLoading
              // {...chatProps.onMobileChatListClick((dat) => {
              //   //console.log("jay,", dat);
              // })}
              activeChatId={activeeChat ? activeeChat : chatProps.activeChatId}
              renderChatList={(chats, index) => {
                if (chats.activeChatId !== activeeChat) {
                  setActiveeChat(chats.activeChatId);
                }
                // if(chatProps.activeChatId == activeeChat){
                //   //console.log(chatProps.activeChatId)
                //   //console.log(activeeChat)
                //   setLoading(false);
                // }
                //console.log("ketul", chats);
                return (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        overflowY: "scroll",
                      }}
                    >
                      {/* <ChatList activeChatId={} /> */}
                      <ChatForm
                        title="My Chatss"
                        titleStyle={{ fontFamily: "Lato" }}
                        style={{
                          background: "var(--primary-color)",
                          color: "var(--white-color)",
                        }}
                        buttonStyle={{
                          display: "none",
                        }}
                      />
                      {chats.chats.length == 0 ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "80%",
                          }}
                        >
                          <div className="h3 bold-text text-center dark-grey-text">
                            No Chats to Display
                          </div>
                        </div>
                      ) : (
                        <>
                          {chats.chats.map((chat, index) => {
                            var otherPerson = chat.people.find(
                              (person) =>
                                person.person.username !==
                                userInfo.userDetailsId.userEmail
                            );

                            // if (index == 0) //console.log(chat);
                            return (
                              otherPerson && (
                                <ChatCard
                                  avatarUsername={
                                    otherPerson.person.first_name[0] +
                                    otherPerson.person.last_name[0]
                                  }
                                  onClick={() => {
                                    chats.onChatCardClick(chat.id);
                                    setActiveeChat(chat.id);
                                    // setLoading(true);
                                    // //console.log("NEW CHAT");
                                  }}
                                  isActive={chats.activeChatId === chat.id}
                                  description={
                                    chat.last_message.text.length > 0
                                      ? chat.last_message.text
                                      : "Say Hello!"
                                  }
                                  hasNotification={chat.hasNotification}
                                  // onClick={}
                                  style={{
                                    fontFamily: "inherit",
                                  }}
                                  timeStamp={chat.timeStamp}
                                  title={
                                    otherPerson.person.first_name +
                                    " " +
                                    otherPerson.person.last_name
                                  }
                                  //styles
                                  activeStyle={{
                                    backgroundColor: "#f26654",
                                    border: "2px solid #c4c4c4",
                                    borderRadius: 0,
                                  }}
                                  avatarStyle={{
                                    border: "2px solid white",
                                    fontFamily: "Lato",
                                  }}
                                  hoveredStyle={{
                                    background: "#f266544d",
                                    borderRadius: 0,
                                  }}
                                  notificationStyle={{
                                    backgroundColor: "#f26654",
                                  }}
                                  titleStyle={{
                                    fontFamily: "Lato",
                                  }}
                                  subtitleStyle={{
                                    fontFamily: "Lato",
                                    textAlign: "left",
                                  }}
                                  timeStampStyle={{
                                    fontFamily: "Lato",
                                  }}
                                />
                              )
                            );
                          })}
                        </>
                      )}
                    </div>
                  </>
                );
              }}
              renderChatFeed={(feeds) => {
                // console.log("k2l", feeds, activeeChat, location?.state?.sellerEmail);
                // console.log(Boolean(((feeds.messages.length > 0 &&
                //   feeds.chat.last_message.text ===
                //     feeds.messages[feeds.messages.length - 1].text) ||
                //   (!feeds.chat.last_message.created &&
                //     feeds.messages.length === 0))))
                // if (chatProps.isChatFeedLoading) {
                //   return (
                //     <div
                //       style={{
                //         display: "flex",
                //         justifyContent: "center",
                //         alignItems: "center",
                //         height: "100%",
                //       }}
                //     >
                //       <div className="large-text grey-text text-center">
                //         <Spinner />
                //       </div>
                //     </div>
                //   );
                // }
                if (
                  feeds.chat &&
                  feeds.chat.id == activeeChat &&
                  !(
                    (feeds.messages.length > 0 &&
                      feeds.chat.last_message.text ===
                        feeds.messages[feeds.messages.length - 1].text) ||
                    (!feeds.chat.last_message.created &&
                      feeds.messages.length === 0)
                  ) &&
                  location?.state?.sellerEmail &&
                  loading === false
                ) {
                  setLoading(true);
                  getOrCreateChat(setActiveChat);
                }
                if (
                  feeds.chat &&
                  feeds.chat.id == activeeChat 
                  &&
                  ((feeds.messages.length > 0 &&
                    feeds.chat.last_message.text ===
                      feeds.messages[feeds.messages.length - 1].text) ||
                    (!feeds.chat.last_message.created &&
                      feeds.messages.length === 0))
                ) {
                  //console.log("k2lA", feeds);
                  setLoading(false);
                  if (feeds && feeds.chat) {
                    //console.log(feeds);
                    var otherPerson = feeds.chat.people.find(
                      (person) =>
                        userInfo.userDetailsId.userEmail !==
                        person.person.username
                    );
                  }

                  // chatProps.onMobileChatListClick(() =>
                  //   chatProps.setIsMobileChatListOpen(
                  //     !chatProps.isMobileChatListOpen
                  //   )
                  // );
                  return (
                    <>
                      {feeds && feeds.chat ? (
                        <>
                          <ChatHeader
                            description={otherPerson.person.username}
                            id={feeds.chat.id}
                            title={
                              otherPerson.person.first_name +
                              " " +
                              otherPerson.person.last_name
                            }
                            titleStyle={{
                              fontFamily: "Lato",
                              margin: ".5rem auto",
                              color: "var(--primary-color)",
                            }}
                            subtitleStyle={{
                              fontFamily: "Lato",
                              color: "var(--grey-color)",
                            }}
                            style={{
                              borderBottom: "2px solid var(--primary-color)",
                            }}
                            mobileOptionStyle={{
                              top: "28px",
                              color: "var(--primary-color)",
                              border: "none",
                              fontSize: "20px",
                            }}
                          />
                          <MessageList
                            {...feeds}
                            onBottomMessageHide={function noRefCheck() {}}
                            onBottomMessageShow={function noRefCheck() {}}
                            onMessageLoaderHide={function noRefCheck() {}}
                            onMessageLoaderShow={function noRefCheck() {}}
                            style={{
                              fontFamily: "Lato",
                              minHeight: "57vh",
                              height: "57vh",
                              overflowX: "hidden",
                              overflowY: "auto",
                            }}
                            messageStyle={{
                              fontFamily: "Lato",
                            }}
                            dateTimeStyle={{
                              fontFamily: "Lato",
                            }}
                          />

                          <MessageForm
                            {...feeds}
                            onChange={function noRefCheck() {}}
                            onSubmit={(message) => {
                              // setLoading(true);
                              feeds.onMessageFormSubmit(message);
                              addToUnseenChat(
                                chatProps.activeChatId,
                                otherPerson.person.username
                              );
                            }}
                            inputStyle={{
                              border: "1px solid var(--grey-color)",
                              borderRadius: "5px",
                              marginLeft: "6px",
                              padding: ".7rem .4rem",
                              top: "2px",
                              fontFamily: "Lato",
                            }}
                            sendButtonStyle={{
                              fontFamily: "Lato",
                              marginLeft: "-.5rem",
                            }}
                          />
                        </>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <div className="large-text grey-text text-center">
                            Start Conversation by Purchasing Items
                          </div>
                        </div>
                      )}
                    </>
                  );
                }
              }}
            />
            <MultiChatSocket
              {...chatProps}
              wsUrl="wss://chatengine.eastus.cloudapp.azure.com:9001"
              onNewMessage={(data) => {
                console.log(data,chatProps)
                if (
                  chatProps.activeChatId == data &&
                  chatProps.chat.last_message.sender_username !==
                    userInfo.userDetailsId.userEmail
                ) {
                  let token = localStorage.getItem("clttoken");
                  dispatch(
                    deleteChatHistory({
                      token,
                      values: { chatId: activeeChat },
                    })
                  );
                  if(chatProps.messages[chatProps.messages.length - 1].created != chatProps.chat.last_message.created){
                    let messages = [
                      ...chatProps.messages,
                      chatProps.chat.last_message
                    ]
                    chatProps.setMessages(messages);
                    chatProps.setIsChatFeedAtBottom(true);
                  }
                } else if (
                  chatProps.chat.last_message.sender_username !==
                  userInfo.userDetailsId.userEmail
                ) {
                  let token = localStorage.getItem("clttoken");
                  dispatch(getuserInfo({ token: token }));
                }
              }}
            />
          </>
        )}
      </div>
    </>
  );
};

export default SupportAdmin;
